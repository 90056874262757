import axios from "axios";
import moment from 'moment';

export const BASE_URL =
  "https://k4x7y3vo87.execute-api.us-east-1.amazonaws.com/prod";
const jwtAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);

const refreshToken = async (user :string, password: string) => {
  const token = await jwtAxios.post('/login', {user, password});
  return token.data;
}

jwtAxios.interceptors.request.use(async request => {
  try{
    const authorization : any = await localStorage.getItem('authentication');
    const authorizationJSON = JSON.parse(authorization);
    let user = authorizationJSON?.user;
    let password = authorizationJSON?.password;
    let token = await localStorage.getItem('token');

    if (!authorization || request.url?.includes('/login')) {
      return request;
    }
  
    const isExpired = token ? isTokenExpired(token) : true;
    if (isExpired) {
      token = await refreshToken(user, password);
      await localStorage.setItem('token', token || '');
    }
  
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  }catch(e){
    return request;
  } 

});

export const isTokenExpired = (token: string) => {
  try {
    const metadata = JSON.parse(global.atob(token.split('.')[1]));
    const time = moment().unix();
    //console.log('Utils::isTokenExpired',  (metadata.exp < time), metadata.exp, time, moment().diff(moment.unix(metadata.exp), 'minute'));
    return metadata.exp < time;
  } catch (error) {
    return true;
  }
};

export const setAuthToken = (token: string | null, user?: any | null) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("authentication", JSON.stringify({user: user?.user,  password: user?.password}) );
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("authentication");
  }
};

export default jwtAxios;
