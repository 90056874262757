import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

const DecimalField: React.FC<TextFieldProps & { onChange: any }> = ({
  children,
  ...props
}) => {
  const { onChange, ...others } = props;

  const handleOnChange = (e: any) => {
    //replace commas by dots
    const finalValue = e.target.value?.replace(/,/g, ".");

    if (onChange) onChange(e, finalValue);
  };

  return <TextField {...others} onChange={handleOnChange} />;
};

export default DecimalField;
