import React, { useContext, useState } from "react";

const OrganizationContext = React.createContext<{
  state: { organization?: string };
  setState: React.Dispatch<React.SetStateAction<any>>;
}>({
  state: { organization: undefined },
  setState: () => null,
});

export const useOrganizationContext = () => useContext(OrganizationContext);

export const OrganizationProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [state, setState] = useState({ organization: undefined });
  return (
    <OrganizationContext.Provider value={{ state, setState }}>
      {children}
    </OrganizationContext.Provider>
  );
};
