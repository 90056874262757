import React from "react";
import List from "@material-ui/core/List";

import routesConfig, { NavItemProps } from "../../../../modules/routesConfig";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalNavGroup from "./VerticalNavGroup";
import { useAuthUser } from "@crema/utility/AppHooks";

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const userAuth = useAuthUser();

  const routesConfigFormat = routesConfig?.map((route) => ({
    ...route,
    //@ts-ignore
    children: route?.children?.map((child) => ({
      ...child,
      url: child?.url?.replace("[id]", userAuth?.fatherOf || userAuth?.uid),
    })),
  }));

  return (
    <List>
      {routesConfigFormat.map((item: NavItemProps) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <VerticalNavGroup item={item} level={0} />}

          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Navigation;
