import React, { useEffect } from "react";
import { Box, MenuItem, Select } from "@material-ui/core";
import { useOrganizations } from "hooks/organizationsHooks";
import { useOrganizationContext } from "../OrganizationProvider/OrganizationProvider";
import { useAuthUser } from "@crema/utility/AppHooks";

export const OrganizationSelector = () => {
  const { state, setState } = useOrganizationContext();
  const [organizations, loading] = useOrganizations();
  const profile = useAuthUser();
  const hasOrganizationPermission = profile?.realRole?.permissions.includes(
    "ORGANIZATION"
  );

  const default_organization = "fe7f6c84-0ed8-42a6-b330-d6f7b9f4bedb"; //proflight

  useEffect(() => {
    if (!loading) {
      if (state.organization !== default_organization) {
        setState({ organization: default_organization });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return !loading && hasOrganizationPermission ? (
    <>
      <Select
        value={
          state.organization ? state.organization : organizations?.items[0]
        }
        onChange={(e) => {
          setState({ organization: e.target.value });
        }}
        placeholder={"Seleccione una empresa"}
      >
        {organizations?.items.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            <Box>{item.name}</Box>
          </MenuItem>
        ))}
      </Select>
    </>
  ) : null;
};
