import { useApiEndpoint } from "./generalHooks";

export interface Organization {
  id: number;
  name: string;
  taxCategory: string;
  identifier: string;
  activityStart: string;
  email: string;
  emailFrom: string;
  representation: string;
}

interface OrganizationResponse {
  pages: number;
  items: Organization[];
}

export interface UseOrganizationsDataParams {
  page?: number;
  query?: string;
}
const defaultPaginationParams: UseOrganizationsDataParams = {
  page: 0,
  query: "",
};

export const useOrganizations = (
  params: UseOrganizationsDataParams = defaultPaginationParams
) => {
  const { page, query } = params;
  const pageParam = page ? `page=${page}&` : "";
  const queryParam = query ? `query=${query}&` : "";
  return useApiEndpoint<OrganizationResponse>(
    `/finance/organizations?${pageParam}${queryParam}`
  );
};

export const useOrganization = (id: string) => {
  return useApiEndpoint<Organization>(
    id ? `finance/organization?id=${id}` : undefined
  );
};
