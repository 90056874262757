import { fetchDataApi } from "@crema/utility/ApiHook";
import { useEffect, useState } from "react";

export type ApiEndpointHookResponse<Type> = [
  Type | null,
  boolean,
  unknown,
  () => void
];

export type ApiCrudResponse<Type> = [
  boolean,
  unknown,
  (requestData: Type) => Promise<void>
];

export function useApiEndpoint<Type>(
  url?: string
): ApiEndpointHookResponse<Type> {
  const [data, setData] = useState<Type | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const fetchData = async () => {
    try {
      setLoading(true);
      if (url) {
        const response = await fetchDataApi<Type>(url);
        setData(response);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [data, loading, error, fetchData];
}

//METOROLOGY: METAR
export const useMetar = () => {
  return useApiEndpoint<any>("/metar");
};
//LIBRARY: FILES
export const useLibraryFiles = () => {
  return useApiEndpoint<any>("/files");
};
