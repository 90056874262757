import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {
  FooterType,
  HeaderType,
  LayoutType,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from "../../../shared/constants/AppEnums";

export const LightSidebar = {
  sidebarBgColor: "#fff",
  sidebarTextColor: "rgba(0, 0, 0, 0.60)",
  sidebarHeaderColor: "#fff",
  sidebarMenuSelectedBgColor: "#F4F7FE",
  sidebarMenuSelectedTextColor: "rgba(0, 0, 0, 0.87)",
  mode: ThemeMode.LIGHT,
};

const breakpoints = createBreakpoints({});
const cardRadius = ThemeStyleRadius.MODERN;
const defaultConfig: any = {
  theme: {
    spacing: 4,
    direction: "ltr", //ltr, rtl
    palette: {
      type: ThemeMode.LIGHT,
      background: {
        paper: "#FFFFFF",
        default: "#f3f4f6",
      },
      // background: { //dark
      //   paper: "#313541",
      //   default: "#393D4B",
      // },
      primary: {
        main: "#0A8FDC",
        contrastText: "#fff",
      },
      secondary: {
        main: "#F04F47",
      },
      sidebar: {
        bgColor: "#313541",
        textColor: "#ffffff", // "#808183"
        //colorSet: LightSidebar,
      },
      gray: {
        50: "#fafafa",
        100: "#f7fafc",
        200: "#edf2f7",
        300: "#E0E0E0",
        400: "#c5c6cb",
        500: "#A8A8A8",
        600: "#666666",
        700: "#4a5568",
        800: "#201e21",
        900: "#1a202c",
        A100: "#d5d5d5",
        A200: "#aaaaaa",
        A400: "#303030",
        A700: "#616161",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.57)",
        secondary: "rgba(0, 0, 0, 0.67)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    //   text: {//dark
    //     primary: "rgba(255, 255, 255, 0.87)",
    //     secondary: "rgba(255, 255, 255, 0.67)",
    //     disabled: "rgba(255, 255, 255, 0.38)",
    //     hint: "rgba(255, 255, 255, 0.38)",
    //   },
    // },
    status: {
      danger: "orange",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: 22,
        },
        h2: {
          fontSize: 20,
        },
        h3: {
          fontSize: 18,
        },
        h4: {
          fontSize: 16,
        },
        h5: {
          fontSize: 14,
        },
        h6: {
          fontSize: 14,
        },
        subtitle1: {
          fontSize: 14,
        },
        subtitle2: {
          fontSize: 16,
        },
        body1: {
          fontSize: 14,
        },
        body2: {
          fontSize: 12,
        },
      },
      MuiToggleButton: {
        root: {
          borderRadius: cardRadius,
        },
      },
      MuiCardLg: {
        root: {
          // borderRadius:
          //   cardRadius === ThemeStyleRadius.STANDARD
          //     ? ThemeStyleRadius.STANDARD
          //     : ThemeStyleRadius.MODERN + 20,
          borderRadius: cardRadius,
        },
      },
      MuiCard: {
        root: {
          borderRadius: cardRadius,
          boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.04)",
          "& .MuiCardContent-root:last-child": {
            paddingBottom: 16,
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: cardRadius,
          boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.04)",
          [breakpoints.down("md")]: {
            paddingTop: "8px !important",
            paddingBottom: "8px !important",
          },
        },
      },
    },
  },
  themeStyle: ThemeStyle.MODERN,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.MINI_SIDEBAR_TOGGLE,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.LIGHT,
  rtAnim: RouteTransition.NONE,
  footer: false,
  locale: {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es",
  },
  rtlLocale: ["ar"],
};
export default defaultConfig;
