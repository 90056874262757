import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from "../../../shared/constants/AppEnums";
import AppAnimate from "../AppAnimate";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerHeader: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    paddingBottom: 16,
    [theme.breakpoints.up("xl")]: {
      paddingTop: 16,
    },
    minHeight: 56,
  },
  linkIcon: {
    paddingLeft: 4,
  },
  textbase: {
    fontSize: 15,
    fontWeight: Fonts.REGULAR,
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
}));

interface ComponentHeaderProps {
  title: string;
  alternativeTitle?: string;
  description?: string;
  refUrl?: string | any;
  textUrl?: string;
  leftIconUrl?: any;
  rightIconUrl?: any;
  rightContent?: any;
  leftContent?: any;
}

const ComponentHeader: React.FC<ComponentHeaderProps> = ({
  title,
  alternativeTitle,
  description,
  refUrl,
  textUrl,
  leftIconUrl,
  rightIconUrl,
  rightContent,
  leftContent,
}) => {
  const classes = useStyles();

  const { messages } = useIntl();
  const history = useHistory();

  return (
    <AppAnimate animation="transition.slideDownIn" delay={300}>
      <Box className={classes.containerHeader}>
        <Box mb={0} pr={{ sm: 3 }} flex={{ sm: 1 }}>
          <Box
            component="h3"
            color="text.primary"
            fontWeight={Fonts.MEDIUM}
            fontSize={{ xs: 18, sm: 20 }}
          >
            {title}
            {alternativeTitle}
          </Box>
          {description ? (
            <Typography
              variant="h6"
              className={classes.textbase}
              color="textSecondary"
            >
              {description}
            </Typography>
          ) : null}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={15}
          flexDirection={"row-reverse"}
        >
          {rightContent ? <Box>{rightContent}</Box> : null}
          {refUrl ? (
            <Box height={40}>
              <Button
                variant="outlined"
                color="primary"
                style={leftIconUrl ? { paddingLeft: 6 } : {}}
                onClick={
                  typeof refUrl !== "string"
                    ? refUrl
                    : () => history.push(refUrl)
                }
              >
                <Box className={classes.actionWrapper}>
                  <>{leftIconUrl}</>
                  <span>
                    {(messages[textUrl as string] as string) ?? "GO BACK"}
                  </span>
                  <>{rightIconUrl}</>
                </Box>
              </Button>
            </Box>
          ) : null}
          {leftContent ? <Box>{leftContent}</Box> : null}
        </Box>
      </Box>
    </AppAnimate>
  );
};

export default ComponentHeader;
