import React from "react";
import { Redirect } from "react-router-dom";

import { createRoutes } from "../@crema/utility/Utils";
import { samplePagesConfig } from "./sample";
import { errorPagesConfigs } from "./errorPages";
import { authRouteConfig } from "./auth";

const routeConfigs = [
  ...samplePagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={"/welcome"} />,
  },
  {
    component: () => <Redirect to="/error-pages/error-404" />,
  },
];

export default routes;
