import React from "react";
import useStyles from "./AppHeader.style";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
/*import MoreIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import LanguageSwitcher from "../../LanguageSwitcher";
import HeaderMessages from "../../HeaderMessages";
import Notifications from "../../Notifications";*/
import Box from "@material-ui/core/Box";
import AppLogo from "../../../../shared/components/AppLogo";
import {
  toggleNavCollapsed,
  useLayoutActionsContext,
} from "../LayoutContextProvider";
import { OrganizationSelector } from "modules/organizations/OrganizationSelector/OrganizationSelector";

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useLayoutActionsContext()!;

  /*const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

 function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}>
      <MenuItem className={classes.menuItemRoot}>
        <HeaderMessages />
      </MenuItem>
      <MenuItem className={classes.menuItemRoot}>
        <Notifications />
      </MenuItem>
      <LanguageSwitcher />
    </Menu>
  );
*/
  return (
    <>
      <AppBar className="app-bar" color="inherit">
        <Toolbar className={classes.appToolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
          >
            <MenuIcon className={classes.menuIconRoot} />
          </IconButton>

          <AppLogo />
          <Box className={classes.grow} />
          <OrganizationSelector />
        </Toolbar>
      </AppBar>
      {/*renderMobileMenu*/}
    </>
  );
};
export default AppHeader;
