import { useContext, useEffect, useState } from "react";
import { AuthType } from "../../shared/constants/AppEnums";
import { defaultUser } from "../../shared/constants/AppConst";
import jwtAxios from "../services/ApiConfig";
import { AuthUser } from "../../types/models/AuthUser";

import {
  fetchStart,
  fetchSuccess,
  useInfoViewActionsContext,
} from "../core/InfoView/InfoViewContext";
import AppContext from "./AppContext";
import AppContextPropsType from "../../types/AppContextPropsType";

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const [loading, setLoading] = useState(true);
  const { user, updateAuthUser } = useContext<AppContextPropsType>(AppContext);
  const dispatch = useInfoViewActionsContext()!;

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      jwtAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      try {
        const res = await jwtAxios.get("/profile");

        dispatch(fetchSuccess());
        updateAuthUser({
          authType: AuthType.JWT_AUTH,
          displayName: res.data.name,
          fatherOf: res.data.father,
          email: res.data.mail,
          role: defaultUser.role,
          realRole: res.data.realRole, //v2
          token: token,
          uid: res.data.id,
          photoURL: `https://v1.proflight.com.ar/imagenes_perfiles/${res.data?.photo}`,
        });

        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch, updateAuthUser]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const { user } = useContext<AppContextPropsType>(AppContext);
  if (user) {
    return user;
  }
  return null;
};

export const useRole = (): string => {
  const user = useAuthUser();
  return user?.realRole.role;
};
